.order-search {
  margin-left: 3px;

  .form-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center
  }

  .searchBox{
    padding: 18px 30px 18px 33px !important;
  }

  .input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 400px;
  }

  .form-control {
    padding: 18px 30px 18px 14px;
    border-radius: 3px;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    height: 38px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #DCE3EB;
    -webkit-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
    -moz-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  }

  .input-group-btn {
    left: 10px;
    top: 9px;
    position: absolute;
  }

  .down-search {
    position: absolute;
    right: 8px;
    top: 9px;
    padding-right: 11px;
    text-align: left;
    width: 84px;

    .tclick {
      width: 90px;
      height: 22px;
      text-align: right;
      background-color: #fff;
      cursor: pointer;
      padding: 0;
    }

    .payout-schedule-menu {
      padding: 5px 20px 9px;
      left: -156px;
      top: 35px;
      width: 240px;
      list-style: none;
      position: absolute;
      background-color: #fff;
      -webkit-box-shadow: 2px 3px 6px rgba(126, 142, 159, 0.1);
      box-shadow: 2px 3px 6px rgba(126, 142, 159, 0.1);
      border: 1px solid rgba(126, 142, 159, 0.1);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      z-index: 88;
      margin: 0;

      li {
        padding: 12px 0;
      }
    }

    .lp-radio {
      position: relative;
      justify-content: flex-start;
      cursor: pointer;

      .cno {
        padding-left: 30px;
        font-weight: 400;
        color: #000;
      }

      input[type="radio"] {
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        opacity: 0;
      }
      input:checked ~.check-mark {
        background-color: #27CBCC;
        border: 1px solid #13bebe;
        font-weight: bold;

        &:after {
          display: block;
        }
      }
      .check-mark {
        position: absolute;
        top: -3px;
        left: 0;
        height: 19px;
        width: 19px;
        background-color: #fff;
        border: 1px solid #e7e7e7;
        border-radius: 50%;
        -webkit-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
        -moz-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
        box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);

        &:after {
          content: '';
          width: 7px;
          height: 7px;
          position: absolute;
          top: 5px;
          left: 5px;
          background-color: #fff;
          border-radius: 50%;
        }
      }
    }
  }

  .payout-schedule {
    text-decoration: none;
    outline: none;
    border: 0;
    display: flex;
    justify-content: flex-end;
    .option-text {
      line-height: 22px;
      color: #6C7488;
      font-size: 15px;
      margin-top: 1px;
    }
    .arrow-down {
      width: 20px;
      height: 20px;
      transition: transform 0.3s ease-in-out 0s;

      &.up {
        transform: rotate(180deg);
      }
    }
  }
}