.merchants-order-filter {
  &.lp-filers {
    display: flex;
  }
  &.lp-nav {
    position: relative;
    padding: 0 60px;


    .nav {
      padding-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      list-style: none;
      margin: 16px 0 0;
      width: 100%;
      border-bottom: 1px solid #e7e7e7;
    }
    .nav-item {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .tclick,
  .tclick-inside {
    color: #6C7488;
    position: relative;
  }
  [class^='nav'] li > a, [class*=' nav'] li > a {
    display: block;
  }
  .payout-schedule:hover,
  .payout-schedule:focus,
  .payout-schedule {
    text-decoration: none;
    outline: none;
    border: 0;
    font-size: 15px;
  }

  .payout-schedule-menu-inside,
  .payout-schedule-menu {
    padding: 11px 20px 10px;
    width: 230px;
    list-style: none;
    position: absolute;
    top: 49px;
    background-color: #fff;
    -webkit-box-shadow: 2px 3px 6px 0 rgba(126, 142, 159, 0.1);
    box-shadow: 2px 3px 6px 0 rgba(126, 142, 159, 0.1);
    border: 1px solid rgba(126, 142, 159, 0.1);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 88;
    left: 60px;
  }

  .payout-schedule-menu li {
    padding: 7px 0;
    font-size: 15px;
  }

  .lp-option {
    display: block;
    position: relative;
    padding-left: 29px;
    margin-bottom: 4px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-width: 236px;
    color: #000;
    font-weight: 500;
    font-family: "Acumin Pro", Arial, sans-serif;

    input {
      padding: 17px 17px 12px;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .check-mark {
      top: -3px;
      left: 0;
      position: absolute;
      height: 19px;
      width: 19px;
      background-color: #fff;
      border: 1px solid #e7e7e7;
      border-radius: 3px;
      -webkit-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
      -moz-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
      box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);

      &.checked {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAqUExURQBigP///y5+loCwv+vy9YKywOzz9TB/lwxphViYq+jw8xhwi3Spufb5+lHY4DEAAAAzSURBVAjXY2AgFjBPgDICV4DIDQysIgog4XSGQCewvFgpWICBwfCiE1QLVICB4RCxtgAA1iYGclVESY8AAAAASUVORK5CYII=");
      }
    }
  }

  .outer-btn {
    display: flex;
    outline: none;
    border: none;
    background: #fff;
    cursor: pointer;
    align-items: center;
  }
  .arrow-down {
    width: 20px;
    fill: #6C7488;
    transition: transform 0.3s ease-in-out 0s;
    &.up {
      transform: rotate(180deg);
    }
  }
}