.companies-page {
  .card {
    background-color: transparent;
    margin-bottom: 10px;
    border-radius: 0;
    border: none;
    padding: 0 7px;
  }

  .item-list {
    list-style: none;
    margin: 0;
    line-height: 22px;
    display: flex;
    flex-flow: column nowrap;
    padding: 0;

    .item {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    > li {
      border-top: 1px solid #E6EAEE;
      padding: 8px 0;

      &:first-child,
      &:nth-child(2) {
        border: 0;
      }
    }

    .item-row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: flex-start;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      min-width: 100%;
      position: relative;
    }

    .item-col {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 4px 0px;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 3;
      -ms-flex-positive: 3;
      flex-grow: 3;
      -ms-flex-negative: 3;
      flex-shrink: 3;
      margin-right: 0;
      min-width: 0;

      &.item-col-title {
        text-align: left;
        margin-left: 0;
        margin-right: auto;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 3;
        -ms-flex-positive: 3;
        flex-grow: 3;
      }
      &.item-col-lgr {
        max-width: 250px;
      }
      &.item-col-header span {
        font-size: 15px;
        color: #6C7488;
      }
      &.custom-col-xsr {
        max-width: 335px;

        .item {
          width: 100%;
        }

        .slug {
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 180px;
          display: block;
          overflow: hidden;
          font-size: 16px;
        }
      }
      &.fixed {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        position: absolute;
        right: 0;
      }
      &.item-col-actions-dropdown {
        padding-right: 0;
        width: 20px;
        height: 56px;
        cursor: pointer;
      }
      .item-actions-dropdown {
        width: 100%;
      }
    }

    .price {
      font-size: 16px;
      line-height: 20px;
      color: #354052;

      .hours-mins {
        margin-left: 5px;
      }
    }
  }

  .dots-icon {
    display: block;
  }

  .media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .wicons {
    border: 1px #989FB2 solid;
    width: 36px;
    height: 36px;
  }

  .feature-icon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
  }

  .order-id {
    font-size: 13px;
    padding-top: 10px;
    font-weight: bold;
  }

  .custom-fz-link {
    font-size: 13px;
  }

  .custom-email-truncate {
    margin-top: 3px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a:hover {
      text-decoration: underline;
      color: #354052;
    }
  }

  .lp-color-grey {
    color: #354052;
  }

  .hide-m-b {
    display: inline-block;
  }

  .item-actions-block {
    display: none;

    &.active {
      display: block;
    }
  }

  .item-actions-block {
    max-width: 0;
    opacity: 0;
    line-height: 30px;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 24px;
    background-color: #fff;
    padding: 15px 20px;
    border: 1px solid #e7e7e7;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
    border-radius: 3px;
    transition: all 0.15s ease-in-out;
    z-index: 1;

    &.active {
      display: block;
      min-width: 238px;
      max-width: 450px;
      opacity: 1;
    }

    .item-actions-list {
      list-style: none;
      white-space: nowrap;
      padding: 0;
      margin: 0;

      li {
        padding: 3px 0;
        margin: 0;
        &:first-child {
          border: 0;
        }
      }

      a {
        color: #6C7488;
        display: block;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
  .link-btn {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}