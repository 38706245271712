.empty-payments-list {
  &.lp-notice {
    height: 50vh;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 24px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 0;
    color: #354052;
    font-family: 'Acumin Regular', Arial, sans-serif;
  }
}