@font-face {
  font-family: 'Acumin Pro';
  src: url('assets/fonts/acumin-pro-light.eot');
  src: url('assets/fonts/acumin-pro-light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/acumin-pro-light.woff2') format('woff2'), url('assets/fonts/acumin-pro-light.woff') format('woff'),
    url('assets/fonts/acumin-pro-light.ttf') format('truetype');
}
@font-face {
  font-family: 'Acumin Regular';
  src: url('assets/fonts/Acumin-RPro.eot');
  src: url('assets/fonts/Acumin-RPro.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Acumin-RPro.woff2') format('woff2'), url('assets/fonts/Acumin-RPro.woff') format('woff'),
    url('assets/fonts/Acumin-RPro.ttf') format('truetype');
}

@font-face {
  font-family: 'AcuminProMedium';
  src: url('assets/fonts/AcuminProMedium.eot');
  src: url('assets/fonts/AcuminProMedium.eot') format('embedded-opentype'),
    url('assets/fonts/AcuminProMedium.woff2') format('woff2'), url('assets/fonts/AcuminProMedium.woff') format('woff'),
    url('assets/fonts/AcuminProMedium.ttf') format('truetype'),
    url('assets/fonts/AcuminProMedium.svg#AcuminProMedium') format('svg');
}

:root {
  --lp-colors-medium-blue-600: #0016D1;
  --lp-colors-medium-blue-800: #000E8F;
  --lp-colors-turquoise-600: #43E3FF;
}

* {
  box-sizing: border-box;
  font-family: 'Acumin Pro', Arial, sans-serif;
}

body {
  margin: 0;
}

.App {
  padding-left: 240px;
  position: relative;
  width: 100%;
  min-height: 100vh;

  &.no-sidebar {
    padding-left: 0;
  }
}
